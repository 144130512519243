import React, { useEffect } from "react";
import { useGetOrder } from "../../services/order.services";

const OrderPending: React.FC = () => {
  const orderId = localStorage.getItem("CURRENT_ORDER_ID");
  const { order } = useGetOrder(orderId!);

  useEffect(() => {
    if (order?.status === 2) {
      localStorage.setItem("ORDER_STATUS", "TRADER_FOUND");
    }
  }, [order]);

  return (
    <div className="main client-main-load">
      <div className="body">
        <h1 className="client-main-load__title title">Ожидание</h1>
        <div className="client-main-load__body">
          <img className="client-main-load__logo" src="./img/logo.svg"></img>
          <div className="client-main-load__progress progress-load">
            <div className="progress-load__item progress-completed">
              <div className="progress-load__item-circle">
                <div className="progress-load__item-icon"></div>
                <p className="progress-load__item-text">Создаем транзакцию</p>
              </div>
              <div className="progress-load__item-line"></div>
            </div>

            <div className="progress-load__item progress-active">
              <div className="progress-load__item-circle">
                <div className="progress-load__item-icon"></div>
                <p className="progress-load__item-text">Сверяем информацию</p>
              </div>
              <div className="progress-load__item-line"></div>
            </div>

            <div className="progress-load__item">
              <div className="progress-load__item-circle">
                <div className="progress-load__item-icon"></div>
                <p className="progress-load__item-text">
                  Подтверждаем получателя
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <button className="button disable">Подождите еще чуть-чуть</button>
      </div>
    </div>
  );
};

export default OrderPending;
