import React, { useEffect, useMemo, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate } from "react-router-dom";
import Dropdown from "../../components/Form/Dropdown";
import { Controller, useForm } from "react-hook-form";
import { ICurrency } from "../../models/currencies.services";
import { useGetExchangePairs } from "../../services/exchangePairs.services";
import { ROUTES } from "../../routes/routes";
import { useGetPaymentMethods } from "../../services/paymentMethod.services";
import PaymentMethodDropdown from "../../components/Form/PaymentMethodDropdown";
import { IPaymentMethod } from "../../models/paymentMethod.model";
import { useCreateOrder } from "../../services/order.services";
import { INewOrderForm } from "../../models/order.service";
import { useGetMe } from "../../services/user.services";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

const Main: React.FC = () => {
  const { t }: { t: (key: string) => string } = useTranslation();
  const { me } = useGetMe();
  const [paymentType, setPaymentType] = useState<"CARD" | "CASH">("CARD");

  const navigate = useNavigate();

  const { exchangePairs } = useGetExchangePairs();
  const { paymentMethods } = useGetPaymentMethods();
  const { mutate: createOrder } = useCreateOrder(
    (data) => {
      localStorage.setItem("ORDER_STATUS", "PENDING");
      localStorage.setItem("CURRENT_ORDER_ID", data.orderId.toString());
    },
    (error) => {
      toast.error(error);
    }
  );

  const OrderFormSchema = yup.object().shape({
    customerCurrencyId: yup.number().required("Выберите валюту!"),
    receiverCurrencyId: yup.number().required("Выберите валюту!"),
    customerId: yup.number().required(""),
    amount: yup.number().required("Введите сумму перевода!"),
    paymentMethodId: yup.number(),
  });

  const useFormReturn = useForm({
    resolver: yupResolver(OrderFormSchema),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setValue,
  } = useFormReturn;

  const submitOrderForm = (data: INewOrderForm) => {
    if (paymentMethods.length === 0 && !data.paymentMethodId) {
      navigate(ROUTES.REQUISITES);
    } else {
      createOrder(data);
    }
  };

  const customerCurrencyId = watch("customerCurrencyId");
  const receiverCurrencyId = watch("receiverCurrencyId");
  const amount = watch("amount");

  const exchangeRate = useMemo(() => {
    const selectedPair = exchangePairs?.find(
      (pair: any) =>
        pair.baseCurrencyId.id === customerCurrencyId &&
        pair.targetCurrencyId.id === receiverCurrencyId
    );
    return selectedPair?.exchangeRate || 0;
  }, [exchangePairs, customerCurrencyId, receiverCurrencyId]);

  const availableCurrencies = useMemo(() => {
    const uniqueCurrencies: ICurrency[] = [];
    const currencyIds = new Set();

    exchangePairs?.forEach((pair: any) => {
      const { baseCurrencyId, targetCurrencyId } = pair;

      [baseCurrencyId, targetCurrencyId].forEach((currency) => {
        if (!currencyIds.has(currency.id)) {
          uniqueCurrencies.push(currency);
          currencyIds.add(currency.id);
        }
      });
    });

    return uniqueCurrencies;
  }, [exchangePairs]);

  useEffect(() => {
    if (availableCurrencies && availableCurrencies?.length > 0 && me) {
      setValue("customerCurrencyId", availableCurrencies[0]?.id!);
      setValue("receiverCurrencyId", availableCurrencies[1]?.id!);
      setValue("customerId", me?.id);
    }
  }, [availableCurrencies, me]);

  useEffect(() => {
    if (paymentMethods && paymentMethods?.length > 0) {
      setValue("paymentMethodId", paymentMethods[0]?.id);
    }
  }, [paymentMethods]);

  const receivedAmount = amount && exchangeRate ? amount * exchangeRate : 0;

  return (
    <form onSubmit={handleSubmit(submitOrderForm)} className="main client-main">
      <div className="body">
        <button className="help">
          <img className="help__icon" src="/img/svg/help.svg" alt="" />
          <p className="help__text">Помощь</p>
        </button>
        <h1 className="client-main__title title">Отправить перевод</h1>
        <div className="client-main__form form">
          <div className="client-main__form-items form__items">
            <Controller
              name="customerCurrencyId"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Dropdown
                  id="customerCurrencyId"
                  currencies={availableCurrencies.filter(
                    (currency) => currency.id !== receiverCurrencyId
                  )}
                  selectedCurrency={
                    availableCurrencies.find(
                      (currency) => currency?.id === value
                    )!
                  }
                  onCurrencyChange={(item) => {
                    onChange(item?.id);
                  }}
                  label="Отправляете"
                />
              )}
            />
            <div className="form__item">
              <Controller
                name="amount"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <>
                    <input
                      className="form-control form-control--transfer"
                      type="number"
                      placeholder="Введите сумму перевода"
                      value={value || ""}
                      onChange={onChange}
                    />
                    {Boolean(errors.amount?.message) && (
                      <p className="error">{errors.amount?.message}</p>
                    )}
                  </>
                )}
              />
              <div className="form__item-bottom">
                Лимит:{" "}
                {availableCurrencies.find(
                  (item) => item.id === customerCurrencyId
                )?.limit || availableCurrencies[0]?.limit}{" "}
                {availableCurrencies.find(
                  (item) => item.id === customerCurrencyId
                )?.code || availableCurrencies[0]?.code}
              </div>
            </div>

            <Controller
              name="receiverCurrencyId"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Dropdown
                  id="receiverCurrencyId"
                  currencies={availableCurrencies.filter(
                    (currency) => currency.id !== customerCurrencyId // Исключаем выбранную "Отправляете"
                  )}
                  selectedCurrency={
                    availableCurrencies.find(
                      (currency) => currency?.id === value
                    )!
                  }
                  onCurrencyChange={(item) => {
                    onChange(item?.id);
                  }}
                  label="Получаете"
                  rightText={`Курс: 1 ${
                    availableCurrencies.find(
                      (item) => item.id === customerCurrencyId
                    )?.code
                  } = ${Number(exchangeRate)?.toFixed(2)} ${
                    availableCurrencies.find(
                      (item) => item.id === receiverCurrencyId
                    )?.code
                  }`}
                />
              )}
            />
          </div>

          <div className="client-main__form-received">
            {receivedAmount.toFixed(2)}{" "}
            {availableCurrencies.find((item) => item.id === receiverCurrencyId)
              ?.code || availableCurrencies[1]?.code}
          </div>

          <div className="client-main__form-options options">
            <div className="options__item">
              <input
                id="card"
                type="radio"
                name="payment"
                className="options__input"
                onChange={() => setPaymentType("CARD")}
                checked={paymentType === "CARD"}
              />
              <label htmlFor="card" className="options__label">
                Карта
              </label>
            </div>
            <div className="options__item">
              <input
                id="cash"
                type="radio"
                name="payment"
                className="options__input"
                onChange={() => setPaymentType("CASH")}
                checked={paymentType === "CASH"}
              />
              <label htmlFor="cash" className="options__label">
                Наличными
              </label>
            </div>
          </div>

          {paymentMethods?.length > 0 && paymentType === "CARD" && (
            <Controller
              name="paymentMethodId"
              control={control}
              render={({ field: { value, onChange } }) => (
                <PaymentMethodDropdown
                  paymentMethods={paymentMethods}
                  onMethodChange={(value) => onChange(value.id)}
                  selectedPaymentMethod={paymentMethods?.find(
                    (method: IPaymentMethod) => method.id === value
                  )}
                />
              )}
            />
          )}
          {paymentType === "CARD" && (
            <Link
              to={ROUTES.REQUISITES}
              className="client-main__form-requisites"
            >
              Добавить реквизиты
            </Link>
          )}
        </div>
      </div>
      <div className="footer">
        <button type="submit" className="button">
          {paymentType === "CARD" ? "Перевести" : "Оформить перевод"}
        </button>
      </div>
    </form>
  );
};

export default Main;
