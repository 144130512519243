import React, { useState, useEffect, useRef } from "react";
import { io } from "socket.io-client";
import { useGetMe } from "../../services/user.services";
import {
  useCloseSupportChat,
  useGetMessagesFromSupportChat,
  useReadMessage,
} from "../../services/chat.services";
import { v4 as uuidv4 } from "uuid"; // Импортируем библиотеку для генерации UUID
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

interface Message {
  id: number;
  text: string;
  sender: "user" | "support";
  time: string;
  date: string;
  read?: boolean;
}

const SOCKET_URL = "https://bobster.freeblock.site/websocket";

const Support: React.FC = () => {
  const [socket, setSocket] = useState<any>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [inputValue, setInputValue] = useState("");
  const [ticketId, setTicketId] = useState<string>("");
  const [autoScrollEnabled, setAutoScrollEnabled] = useState(true); // Флаг авто-прокрутки
  const [currentPage, setCurrentPage] = useState(1); // Track the current page

  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const chatContainerRef = useRef<HTMLDivElement | null>(null);

  const { me } = useGetMe();
  const { messageList, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useGetMessagesFromSupportChat(ticketId, currentPage); // Pass currentPage to the hook
  const { mutate: readMessage } = useReadMessage(
    () => {},
    () => {}
  );
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  // Прокрутка вниз
  const scrollToBottom = (smooth: boolean = true) => {
    if (messagesEndRef.current && autoScrollEnabled) {
      messagesEndRef.current.scrollIntoView({
        behavior: smooth ? "smooth" : "auto",
      });
    }
  };

  // Завершение чата
  const { mutate: closeSupportChat } = useCloseSupportChat(() => {
    localStorage.removeItem("chatId");
    setTicketId("");
    navigate(-1);
    toast.success("Чат завершен.");
  });

  useEffect(() => {
    let currentChatId = localStorage.getItem("chatId");
    if (!currentChatId) {
      currentChatId = uuidv4();
      localStorage.setItem("chatId", currentChatId!);
    }
    setTicketId(currentChatId!);
  }, []);

  useEffect(() => {
    if (messageList && messageList?.length > 0 && me) {
      const loadedMessages = messageList.map((msg: any) => {
        const sender: "user" | "support" =
          msg.chatMessage_senderId === me?.id ? "user" : "support";

        return {
          id: msg.chatMessage_id,
          text: msg.chatMessage_message,
          sender: sender,
          time: new Date(
            new Date(msg.chatMessage_createdAt)
          )?.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          }),
          date: msg?.chatMessage_createdAt?.split("T")[0],
          read: msg.chatMessage_read,
        };
      });

      if (messages.length > 0) {
        return;
      } else {
        setMessages((prevMessages) => {
          // Объединяем новые данные перед текущими сообщениями
          const newMessages = loadedMessages.filter(
            (msg) => !prevMessages.some((existing) => existing.id === msg.id)
          );
          return [...newMessages, ...prevMessages];
        });

        markMessagesAsRead();
        scrollToBottom(false);
      }
    }
  }, [messageList, me]);

  useEffect(() => {
    const newSocket = io(SOCKET_URL, {
      query: { token: token },
      transports: ["websocket"],
    });

    setSocket(newSocket);

    newSocket.on("connect", () => {
      console.log("Connected to WebSocket server");
    });

    newSocket.on("message", (message) => {
      console.log(message);
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          id: message.id,
          text: message.message,
          sender: message.senderId === me?.id ? "user" : "support",
          time: new Date(new Date(message.createdAt))?.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          }),
          date: message?.createdAt?.split("T")[0],
          read: message?.read,
        },
      ]);
      if (autoScrollEnabled) scrollToBottom(); // Автопрокрутка только при разрешении
    });

    newSocket.on("disconnect", () => {
      console.log("Disconnected from WebSocket server");
    });

    return () => {
      newSocket.off("message");
      newSocket.close();
    };
  }, [token]);

  // Прокрутка вниз при изменении сообщений
  useEffect(() => {
    if (autoScrollEnabled) scrollToBottom();
  }, []);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (inputValue.trim()) {
      const newMessage: Message = {
        id: Date.now(),
        text: inputValue,
        sender: "user",
        time: new Date().toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        }),
        date: new Date().toISOString().split("T")[0],
        read: false,
      };
      if (socket) {
        socket.emit("sendMessageToSupport", {
          senderId: me?.id,
          receiverId: "support",
          ticketId: ticketId,
          message: inputValue,
        });
      }

      setMessages((prevMessages) => [...prevMessages, newMessage]);
      setInputValue("");
    }
  };

  const handleEndChat = () => {
    closeSupportChat(ticketId);
  };

  const groupedMessages = messages.reduce<{ [date: string]: Message[] }>(
    (groups, message) => {
      const date = message.date;
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(message);
      return groups;
    },
    {}
  );

  const markMessagesAsRead = () => {
    const unreadMessages = messages.filter(
      (message) => message.sender === "support" && !message.read
    );

    unreadMessages.forEach((message) => {
      readMessage(message.id.toString());
    });

    setMessages((prevMessages) =>
      prevMessages.map((msg) =>
        msg.sender === "support" && !msg.read ? { ...msg, read: true } : msg
      )
    );
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;

    // If the scroll is near the top, fetch more messages
    if (target.scrollTop === 0 && hasNextPage && !isFetchingNextPage) {
      setCurrentPage((prevPage) => prevPage + 1); // Update current page
      fetchNextPage(); // Fetch the next page
    }

    const isAtBottom =
      target.scrollHeight - target.scrollTop - target.clientHeight < 50;

    setAutoScrollEnabled(isAtBottom);
  };

  return (
    <div className="main chat">
      <div className="body">
        <h1 className="chat__title title">Чат поддержки</h1>
        <div
          className="chat__items"
          ref={chatContainerRef}
          onScroll={handleScroll}
        >
          {Object.keys(groupedMessages).map((date) => (
            <div key={date}>
              <div className="chat__date">{date}</div>

              {groupedMessages[date].map((message) => (
                <div key={message?.id} className="chat__messages">
                  <div
                    className={`chat__message chat__message--${message?.sender}`}
                  >
                    <div className="chat__message-text">{message.text}</div>
                    <div className="chat__message-time">
                      {message.time}
                      {message.sender === "user" && (
                        <div className="chat__message-check">
                          <img
                            className={`chat__message-check-icon ${
                              message.read ? "read" : ""
                            }`}
                            src="/img/svg/check-mes.svg"
                            alt=""
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ))}
          <div ref={messagesEndRef} />
        </div>

        <form className="chat__input-container" onSubmit={handleSubmit}>
          <button className="chat__input-btn">
            <img
              className="chat__input-icon chat__input-icon--emoji"
              src="/img/svg/smile.svg"
              alt=""
            />
          </button>
          <textarea
            className="chat__input"
            placeholder="Start typing..."
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          ></textarea>
          <button className="chat__input-btn">
            <img
              className="chat__input-icon chat__input-icon--mention"
              src="/img/svg/mention.svg"
              alt=""
            />
          </button>
          <button type="submit" className="chat__input-btn">
            <svg className="chat__input-icon chat__input-icon--send">
              <use xlinkHref="/img/sprite.svg#send"></use>
            </svg>
          </button>
        </form>
      </div>
      {messages.length > 0 && (
        <div className="footer chat__actions">
          <button
            className="chat__button chat__button--reject button button--gray-border"
            onClick={handleEndChat}
          >
            Вопрос не решен
          </button>
          <button className="chat__button button" onClick={handleEndChat}>
            Вопрос решен
          </button>
        </div>
      )}
    </div>
  );
};

export default Support;
