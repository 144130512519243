import { useQuery } from "@tanstack/react-query";
import authApi from "../config/authApi.config";

const getCurrencies = async () => {
  return authApi.get("/currencies").then((res) => res.data);
};

export const useGetCurrencies = () => {
  const {
    data: currencies,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["Currencies"],
    queryFn: getCurrencies,
  });

  return {
    currencies,
    isLoading,
    isError,
  };
};
