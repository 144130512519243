import React, { useEffect, useState } from "react";
import { io } from "socket.io-client";

const SOCKET_URL = "https://bobster.freeblock.site/api/v1/api/v1";

const Notifications: React.FC = () => {
  const [socket, setSocket] = useState<any>(null);
  const [notifications, setNotifications] = useState<any>([]);
  
  const token = localStorage.getItem("token");

  useEffect(() => {
    const newSocket = io(SOCKET_URL, {
      query: {
        token,
      },
      transports: ["websocket"],
    });

    setSocket(newSocket);

    newSocket.on("connect", () => {
      console.log("Connected to WebSocket server");
    });

    newSocket.on("notification", (notification) => {
      setNotifications((prevNotifications: any) => [
        ...prevNotifications,
        notification,
      ]);
    });

    newSocket.on("disconnect", () => {
      console.log("Disconnected from WebSocket server");
    });

    // Cleanup on component unmount
    return () => {
      newSocket.close();
    };
  }, []);

  const markNotificationAsRead = (notificationId: number) => {
    if (socket) {
      socket.emit("readNotification", { notificationId });
    }
  };

  console.log(notifications);

  return (
    <div className="main notification">
      <div className="body">
        <h1 className="notification__title title mb-title">Уведомления</h1>
        <ul className="notification__items">
          <li className="notification__item notification-new">
            <a href="#" className="notification__link">
              <div className="notification__img">
                <svg className="notification__icon">
                  <use xlinkHref="/img/sprite.svg#chatbubbles"></use>
                </svg>
              </div>
              <div className="notification__body">
                <div className="notification__date">12.07.2024 15:12</div>
                <div className="notification__descr">
                  <div className="notification__order">#457664</div>
                  <p className="notification__text">
                    <span className="notification__author">Трейдер:</span> Да я
                    в душе не знаю ттт
                  </p>
                </div>
              </div>
            </a>
          </li>
          <li className="notification__item notification-new">
            <a href="#" className="notification__link">
              <div className="notification__img">
                <svg className="notification__icon">
                  <use xlinkHref="/img/sprite.svg#document"></use>
                </svg>
              </div>
              <div className="notification__body">
                <div className="notification__date">12.07.2024 15:12</div>
                <div className="notification__descr">
                  <div className="notification__order">#457664</div>
                  <p className="notification__text">
                    <span className="notification__author">Система:</span> Ордер
                    исполнен.
                  </p>
                </div>
              </div>
            </a>
          </li>
          <li className="notification__item notification-new">
            <a href="#" className="notification__link">
              <div className="notification__img">
                <svg className="notification__icon">
                  <use xlinkHref="/img/sprite.svg#document"></use>
                </svg>
              </div>
              <div className="notification__body">
                <div className="notification__date">12.07.2024 15:12</div>
                <div className="notification__descr">
                  <div className="notification__order">#457664</div>
                  <p className="notification__text">
                    <span className="notification__author">Трейдер:</span>{" "}
                    Просто вводите номер
                  </p>
                </div>
              </div>
            </a>
          </li>
          <li className="notification__item ">
            <a href="#" className="notification__link">
              <div className="notification__img">
                <svg className="notification__icon">
                  <use xlinkHref="img/sprite.svg#document"></use>
                </svg>
              </div>
              <div className="notification__body">
                <div className="notification__date">12.07.2024 15:12</div>
                <div className="notification__descr">
                  <div className="notification__order">#457664</div>
                  <p className="notification__text">
                    <span className="notification__author">Система:</span> Ордер
                    исполнен.
                  </p>
                </div>
              </div>
            </a>
          </li>
          <li className="notification__item ">
            <a href="#" className="notification__link">
              <div className="notification__img">
                <svg className="notification__icon">
                  <use xlinkHref="/img/sprite.svg#document"></use>
                </svg>
              </div>
              <div className="notification__body">
                <div className="notification__date">12.07.2024 15:12</div>
                <div className="notification__descr">
                  <div className="notification__order">#457664</div>
                  <p className="notification__text">
                    <span className="notification__author">Система:</span> Ордер
                    исполнен.
                  </p>
                </div>
              </div>
            </a>
          </li>
          <li className="notification__item ">
            <a href="#" className="notification__link">
              <div className="notification__img">
                <svg className="notification__icon">
                  <use xlinkHref="/img/sprite.svg#document"></use>
                </svg>
              </div>
              <div className="notification__body">
                <div className="notification__date">12.07.2024 15:12</div>
                <div className="notification__descr">
                  <div className="notification__order">#457664</div>
                  <p className="notification__text">
                    <span className="notification__author">Система:</span> Ордер
                    исполнен.
                  </p>
                </div>
              </div>
            </a>
          </li>
        </ul>
      </div>
      <div className="footer">
        <button className="button button--gray-border">Загрузить еще</button>
      </div>
    </div>
  );
};

export default Notifications;
