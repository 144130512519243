import React from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../../routes/routes";

const Header: React.FC = () => {
  return (
    <header className="header">
      <div className="header__row">
        <Link to={ROUTES.MAIN} className="header__action">
          <svg className="header__action-icon">
            <use xlinkHref="/img/sprite.svg#person"></use>
          </svg>
        </Link>
        <Link to={ROUTES.MAIN} className="header__logo">
          <div className="logo">
            <img
              className="logo__icon"
              src="/img/logo.svg"
              alt="logo bobster"
            />
            <div className="logo__text">
              <div className="logo__name">BOBSTER</div>
              <div className="logo__descr">PAYMENTS</div>
            </div>
          </div>
        </Link>
        <Link to={ROUTES.NOTIFICATIONS} className="header__action">
          <svg className="header__action-icon">
            <use xlinkHref="/img/sprite.svg#notification"></use>
          </svg>
        </Link>
      </div>
    </header>
  );
};

export default Header;
