import { useInfiniteQuery, useMutation } from "@tanstack/react-query";
import authApi from "../config/authApi.config";

// Fetch paginated support chat messages
const getMessagesFromSupportChat = async ({
  ticketId,
  pageParam = 1,
}: {
  ticketId: string;
  pageParam?: number;
}) => {
  const response = await authApi.get(
    `/chats/byOrderOrTicket/${ticketId}?isChat=false`,
    {
      params: { page: pageParam, limit: 100 },
    }
  );
  return response.data;
};

// Fetch paginated support chat list
const getSupportChatList = async ({
  pageParam = 1,
  limit = 10,
  ...params
}: {
  pageParam?: number;
  limit?: number;
  keyword?: string;
  isFinished?: boolean;
  isChat?: boolean;
}) => {
  const response = await authApi.get("/chats/supportChatLists", {
    params: {
      page: pageParam,
      limit,
      ...params,
    },
  });
  return response.data as any;
};

const closeSupportChat = async (ticketId: string) => {
  return authApi
    .post(`/chats/finish/ticket/${ticketId}`)
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
};

const sendMessageToSupportChat = async (data: {
  senderId: string;
  message: string;
}) => {
  return authApi
    .post("/chats/sendToSupport", {
      ...data,
    })
    .then((res) => res.data as any)
    .catch((error) => {
      throw new Error(error);
    });
};

const readMessage = async (messageId: string) => {
  return authApi
    .post(`/chats/read/${messageId}`)
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
};

// Use mutation for marking messages as read
export const useReadMessage = (
  onSuccess?: () => void,
  onError?: () => void
) => {
  return useMutation({
    mutationKey: ["ReadMessageById"],
    mutationFn: (messageId: string) => readMessage(messageId),
    onSuccess,
    onError,
  });
};

// Use mutation for sending messages
export const useSendMessageToSupportChat = (
  onSuccess?: () => void,
  onError?: () => void
) => {
  return useMutation({
    mutationKey: ["SendMessageToSupport"],
    mutationFn: (data: { senderId: string; message: string }) =>
      sendMessageToSupportChat(data),
    onSuccess,
    onError,
  });
};

// Use mutation for closing support chat
export const useCloseSupportChat = (
  onSuccess?: () => void,
  onError?: () => void
) => {
  return useMutation({
    mutationKey: ["CloseSupportChat"],
    mutationFn: (ticketId: string) => closeSupportChat(ticketId),
    onSuccess,
    onError,
  });
};

// Use infinite query for fetching chat lists
export const useGetChats = (params: {
  limit?: number;
  keyword?: string;
  isFinished?: boolean;
  isChat?: boolean;
}) => {
  return useInfiniteQuery({
    initialPageParam: 1,
    queryKey: ["chats", params],
    queryFn: ({ pageParam }) => getSupportChatList({ pageParam, ...params }),
    getNextPageParam: (lastPage, allPages) => {
      const hasMorePages = lastPage?.hasMorePages;
      const nextPage = allPages.length + 1;

      return hasMorePages ? nextPage : undefined;
    },
  });
};

// Use infinite query for fetching support chat messages with pagination
export const useGetMessagesFromSupportChat = (
  ticketId: string,
  page: number
) => {
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    isError,
  } = useInfiniteQuery({
    initialPageParam: 1,
    queryKey: ["SupportChatMessages", ticketId],
    queryFn: ({ pageParam = 1 }) =>
      getMessagesFromSupportChat({ ticketId, pageParam }), // Use the pageParam from the query
    getNextPageParam: (lastPage) => {
      // Assuming a fixed page size (e.g., 10 messages per page)
      const pageSize = 10;

      // Check if there are fewer than `pageSize` messages, which means it's the last page
      if (lastPage.length === pageSize) {
        return page + 1; // Return the next page number
      }

      return undefined; // If there are no more pages, return undefined
    },
    enabled: !!ticketId,
  });

  // Flattening and reversing the messages for correct order
  const messageList = data
    ? data.pages.flatMap((page) => page || []).reverse()
    : [];

  return {
    messageList,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    isError,
  };
};
