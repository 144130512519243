import { useEffect, useState } from "react";
import { useLoginMutation } from "../services/auth.services";

export const useAuth = (
  tgId: string,
  userName: string,
  referralCode: string
): { isAuthenticated: boolean; isAuthCompleted: boolean } => {
  const loginMutation = useLoginMutation();

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [attemptCount, setAttemptCount] = useState(0);
  const [errorCount, setErrorCount] = useState(0);
  const [isAuthCompleted, setIsAuthCompleted] = useState(false); // Добавляем флаг завершения

  const maxAttempts = 1;

  useEffect(() => {
    if (!tgId || !userName) {
      console.warn("Authentication skipped: tgId or userName is missing.");
      setIsAuthCompleted(true); // Завершаем попытку, если данные отсутствуют
      return;
    }

    const authenticate = async () => {
      if (
        isAuthenticated ||
        attemptCount >= maxAttempts ||
        errorCount >= maxAttempts
      ) {
        setIsAuthCompleted(true); // Завершаем, если авторизация или регистрация невозможны
        return;
      }

      try {
        await loginMutation.mutateAsync(tgId);
        setIsAuthenticated(true);
      } catch (error) {
        setErrorCount((prev) => prev + 1);
        console.error("Login failed:", error);

        if (attemptCount < maxAttempts) {
          try {
            await loginMutation.mutateAsync(tgId);
            setIsAuthenticated(true);
            setIsAuthCompleted(true);
          } catch (registerError) {
            setErrorCount((prev) => prev + 1);
            setIsAuthCompleted(true); // Завершаем после ошибки
            console.error("Registration failed:", registerError);
          }
        } else {
          setIsAuthCompleted(true); // Завершаем после неудачной попытки
        }
      }
    };

    setAttemptCount((prev) => prev + 1);
    // };

    authenticate();
  }, [
    tgId,
    userName,
  ]);

  return { isAuthenticated, isAuthCompleted }; // Возвращаем состояния
};
