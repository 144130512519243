import React, { useEffect } from "react";
import { useTelegram } from "../../providers/TelegramProvider";
import { Link, useNavigate } from "react-router-dom";
import {
  useDeletePaymentMethod,
  useGetPaymentMethods,
} from "../../services/paymentMethod.services";
import { ROUTES } from "../../routes/routes";
import { IPaymentMethod } from "../../models/paymentMethod.model";
import toast from "react-hot-toast";

const SettingCards: React.FC = () => {
  const { paymentMethods, refetch } = useGetPaymentMethods();
  const { mutate: deletePaymentMethod } = useDeletePaymentMethod(
    () => {
      refetch();
      toast.success("Карта успешно удалена!");
    },
    (error) => {
      toast.error(error);
    }
  );
  const { webApp } = useTelegram();
  const navigate = useNavigate();

  const maskCardNumber = (number: string) => {
    // Убираем все пробелы для обработки
    const cleanNumber = number.replace(/\s+/g, "");

    // Проверяем, что строка состоит из 16 цифр
    if (cleanNumber.length === 16) {
      // Маскируем средние 8 цифр
      const masked = cleanNumber.replace(
        /^(\d{4})(\d{4})(\d{4})(\d{4})$/,
        "$1 **** **** $4"
      );

      return masked;
    }

    // Если формат не соответствует, возвращаем исходное значение
    return number;
  };

  const handleDeletePaymentMethod = (id: number) => {
    deletePaymentMethod(id);
  };

  useEffect(() => {
    if (webApp) {
      webApp.BackButton.onClick(() => {
        navigate(-1);
      });
      webApp.BackButton.show();
    }

    return () => {
      webApp?.BackButton.offClick(() => {
        navigate(-1);
      });
      webApp?.BackButton.hide();
    };
  }, [webApp]);

  return (
    <div className="main cards">
      <div className="body">
        <h1 className="cards__title title mb-title">Мои карты</h1>
        <ul className="cards__items">
          {paymentMethods?.map((method: IPaymentMethod) => {
            const methodDetails = JSON.parse(method?.details);
            const maskedNumber = methodDetails?.Number
              ? maskCardNumber(methodDetails.Number)
              : "";

            if (method.type === "CreditCard") {
              return (
                <li key={method.id} className="cards__item">
                  <label htmlFor="" className="cards__label">
                    Для {methodDetails?.Currency}
                  </label>
                  <div className="cards__body">
                    <img
                      className="cards__icon"
                      src={`/img/card-icons/${methodDetails.Type}.svg`}
                      alt=""
                    />
                    <div className="cards__info">
                      <div className="cards__code">{maskedNumber}</div>
                      <div className="cards__term"></div>
                    </div>
                    <button
                      onClick={() => handleDeletePaymentMethod(method.id)}
                      className="cards__btn-del"
                    >
                      <svg className="cards__btn-del-icon">
                        <use xlinkHref="/img/sprite.svg#trash"></use>
                      </svg>
                    </button>
                  </div>
                </li>
              );
            }
          })}
        </ul>
        <Link
          to={ROUTES.NEW_CARD}
          className="cards__add-btn button button--blue-border"
        >
          Добавить
          <img className="cards__add-btn-icon" src="/img/svg/add.svg" alt="" />
        </Link>
      </div>
    </div>
  );
};

export default SettingCards;
