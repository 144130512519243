import React, { useEffect, useState } from "react";
import { useClientSendMoney, useGetOrder } from "../../services/order.services";
import toast from "react-hot-toast";

const SendOrderAmount: React.FC = () => {
  const orderId = localStorage.getItem("CURRENT_ORDER_ID");
  const { order } = useGetOrder(orderId!);
  const { mutate: clientSentMoney } = useClientSendMoney(
    () => {
      localStorage.setItem("ORDER_STATUS", "CUSTOMER_SENT");
    },
    () => {
      toast.error("Что-то пошло не так... Попробуйте позже!");
    }
  );

  const [timeLeft, setTimeLeft] = useState<number | null>(null);

  useEffect(() => {
    if (!order || !order.traderDepositedAt) return;

    const traderDepositedTime = new Date(order.traderDepositedAt).getTime();
    const endTime = traderDepositedTime + 15 * 60 * 1000; // 15 минут от времени traderDepositedAt

    const updateTimer = () => {
      const currentTime = Date.now();
      const remainingTime = endTime - currentTime;

      if (remainingTime <= 0) {
        setTimeLeft(0); // Если время истекло, устанавливаем в 0
      } else {
        setTimeLeft(remainingTime);
      }
    };

    // Запускаем интервал, обновляющий таймер каждую секунду
    const intervalId = setInterval(updateTimer, 1000);

    // Очищаем интервал при размонтировании компонента
    return () => clearInterval(intervalId);
  }, [order]);

  // Функция для форматирования оставшегося времени в "ЧЧ:ММ:СС"
  const formatTime = (time: number) => {
    const hours = Math.floor(time / (1000 * 60 * 60));
    const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((time % (1000 * 60)) / 1000);
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}:${String(seconds).padStart(2, "0")}`;
  };

  const handleSubmitForm = () => {
    clientSentMoney({
      orderId: orderId!,
    });
  };

  return (
    <div className="main client-transaction-detail">
      <div className="body">
        <button className="help">
          <img className="help__icon" src="/img/svg/help.svg" alt="" />
          <p className="help__text">Помощь</p>
        </button>
        <div className="client-transaction-detail__info transaction-detail-info">
          <h1 className="transaction-detail-info__title title">
            Детали перевода
          </h1>
          <p className="transaction-detail-info__text">
            Переведите{" "}
            <span className="transaction-detail-info__value">
              {order?.amount} RUB
            </span>{" "}
            на указанную карту и подтвердите отправку
          </p>
          <div className="transaction-detail-info__code">
            <div className="transaction-detail-info__code-value">
              2398 0293 0983 0983
            </div>
            <div className="transaction-detail-info__code-actions">
              <button className="copy-btn">
                <svg className="copy-btn__icon copy-btn__icon--white">
                  <use xlinkHref="/img/sprite.svg#copy"></use>
                </svg>
              </button>
              <button className="link-btn">
                <svg className="link-btn__icon link-btn__icon--orange">
                  <use xlinkHref="/img/sprite.svg#link"></use>
                </svg>
              </button>
            </div>
          </div>
          <div className="transaction-detail-info__bottom">
            <div className="transaction-detail-info__bottom-item">
              <label className="transaction-detail-info__bottom-label">
                Имя получателя:
              </label>
              <p className="transaction-detail-info__bottom-text">
                Иван Иванович И.
              </p>
            </div>
            <div className="transaction-detail-info__bottom-item">
              <label className="transaction-detail-info__bottom-label">
                Банк:
              </label>
              <p className="transaction-detail-info__bottom-text">T-Банк</p>
            </div>
          </div>
        </div>
        <div className="client-transaction-detail__summary transaction-detail-summary">
          <div className="transaction-detail-summary__amount">
            2000 {order?.receiverCurrency.code}
          </div>
          <p className="transaction-detail-summary__text">
            Перевод будет зачислен на карту<span> 1234 1234 1234 8888</span>
          </p>
        </div>
        <div className="client-transaction-detail__time transaction-detail-time">
          <p className="transaction-detail-time__text">
            Время до анулирования заявки
          </p>
          <div className="transaction-detail-time__timer">
            {timeLeft !== null ? formatTime(timeLeft) : "Загрузка..."}
          </div>
        </div>
      </div>
      <div className="footer">
        <button onClick={handleSubmitForm} className="button">
          Я оплатил
        </button>
        <div className="guarantee">
          <img
            className="guarantee__icon"
            src="/img/svg/guarantee.svg"
            alt=""
          />
          <p className="guarantee__text">Сделка гарантирована Bobster</p>
        </div>
      </div>
    </div>
  );
};

export default SendOrderAmount;
