import React from "react";

const SupportChat: React.FC = () => {
  return (
    <div className="main chat">
      <div className="body">
        <h1 className="chat__title title">Чат поддержки</h1>
        <div className="chat__date">8/20/2020</div>
        <div className="chat__items">
          <div className="chat__messages">
            <div className="chat__message chat__message--user">
              <div className="chat__message-text">
                Что за скам? Ничего не пришло. Оплатил 20 мин назад!
              </div>
              <div className="chat__message-time chat__message-time--user">
                11:35 AM
                <div className="chat__message-check read">
                  <img
                    className="chat__message-check-icon"
                    src="/img/svg/check-mes.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="chat__messages">
            <div className="chat__message chat__message--support">
              <div className="chat__message-text">
                Решаем вопрос. Уведомили трейдера, ждем фидбек
              </div>
              <div className="chat__message-time">11:36 AM</div>
            </div>
            <div className="chat__message chat__message--support">
              <div className="chat__message-text">
                Ваш банк задержал транзакцию на 19 минут. TRY придут через 10
                мин.
              </div>
              <div className="chat__message-time">11:38 AM</div>
            </div>
          </div>
          <div className="chat__messages">
            <div className="chat__message chat__message--user">
              <div className="chat__message-text">ok</div>
              <div className="chat__message-time chat__message-time--user">
                11:40 AM
                <div className="chat__message-check">
                  <img
                    className="chat__message-check-icon"
                    src="/img/svg/check-mes.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <form className="chat__input-container">
          <button className="chat__input-btn ">
            <img
              className="chat__input-icon chat__input-icon--emoji"
              src="/img/svg/smile.svg"
              alt=""
            />
          </button>
          <textarea
            className="chat__input"
            placeholder="Start typing..."
          ></textarea>
          <button className="chat__input-btn">
            <img
              className="chat__input-icon chat__input-icon--mention"
              src="/img/svg/mention.svg"
              alt=""
            />
          </button>
          <button type="submit" className="chat__input-btn">
            <svg className="chat__input-icon chat__input-icon--send">
              <use xlinkHref="img/sprite.svg#send"></use>
            </svg>
          </button>
        </form>
      </div>
      <div className="footer chat__actions">
        <button className="chat__button chat__button--reject button button--gray-border">
          Вопрос не решен
        </button>
        <button className="chat__button button">Вопрос решен</button>
      </div>
    </div>
  );
};

export default SupportChat;
