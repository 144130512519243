// Loading.tsx
import React from "react";

type LoadingProps = {
  loadingItems: string[]; // Массив с элементами, которые загружаются
  isAuthLoading: boolean; // Состояние для авторизации
};

const Loading: React.FC<LoadingProps> = ({ loadingItems, isAuthLoading }) => {
  return (
    <div className="loading-page">
      <div className="loading-indicator">
        {/* Используем простую анимацию для индикатора загрузки */}
        <div className="spinner"></div>
      </div>
      <div className="loading-text">
        <h2>Loading...</h2>
        {isAuthLoading && <p>Authorization in progress...</p>}
        {!isAuthLoading && (
          <ul>
            {loadingItems.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default Loading;
