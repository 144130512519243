import { useQuery } from "@tanstack/react-query";
import authApi from "../config/authApi.config";

const getLanguage = async (languageId: string) => {
  return authApi.get(`/languages/${languageId}`).then((res) => res.data);
};

const getLanguageFile = async (fileName: string) => {
  return authApi.get(`/languages/getFile/${fileName}`).then((res) => res.data);
};

export const useGetLanguage = (languageId: string) => {
  const {
    data: language,
    isLoading,
    isError,
  } = useQuery({
    queryFn: () => getLanguage(languageId),
    queryKey: ["Language"],
  });

  return {
    language,
    isLoading,
    isError,
  };
};

export const useGetLanguageFile = (fileName: string) => {
  const {
    data: languageFile,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["LanguageFile", fileName],
    queryFn: () => getLanguageFile(fileName),
    enabled: !!fileName,
  });

  return {
    languageFile,
    isLoading,
    isError,
  };
};
