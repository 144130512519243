import { useMutation, useQuery } from "@tanstack/react-query";
import authApi from "../config/authApi.config";

const getPaymentMethods = async () => {
  return authApi
    .get("/payment-methods")
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
};

const addPaymentMethod = async (data: { type: string; details: string }) => {
  return authApi
    .post("/payment-methods", data)
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
};

const deletePaymentMethod = async (id: number) => {
  return authApi
    .delete(`/payment-methods/${id}`)
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
};

export const useGetPaymentMethods = () => {
  const {
    data: paymentMethods,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: ["PaymentMethods"],
    queryFn: getPaymentMethods,
  });

  return {
    paymentMethods,
    isLoading,
    isError,
    refetch,
  };
};

export const useAddPaymentMethod = (
  onSuccess?: () => void,
  onError?: (error: any) => void
) => {
  return useMutation({
    mutationKey: ["AddPaymentMethod"],
    mutationFn: (data: { type: string; details: string }) =>
      addPaymentMethod(data),
    onSuccess,
    onError,
  });
};

export const useDeletePaymentMethod = (
  onSuccess?: () => void,
  onError?: (error: any) => void
) => {
  return useMutation({
    mutationKey: ["DeletePaymentMethod"],
    mutationFn: (id: number) => deletePaymentMethod(id),
    onSuccess,
    onError,
  });
};
