import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  lng: "en", // Default language
  fallbackLng: "en", // Fallback language
  resources: {}, // Placeholder for dynamic language resources
  interpolation: {
    escapeValue: false, // React already escapes by default
  },
});

export default i18n;
