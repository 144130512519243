import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { ROUTES } from "./routes";
import Main from "../Views/Main";
import RootWrapper from "../components/RootWrapper";
import Settings from "../Views/Settings";
import SettingCards from "../Views/SettingCards";
import NewCard from "../Views/NewCard";
import Notifications from "../Views/Notifications";
import History from "../Views/History";
import Support from "../Views/Support";
import SupportChat from "../Views/SupportChat";
import DetailsType from "../Views/DetailsType";
import NewIBAN from "../Views/NewIBAN";
import OrderPending from "../Views/Main/OrderPending";
import OrderView from "../Views/OrderView";

const RoutesWrapper: React.FC = () => {
  return (
    <Routes>
      <Route path={ROUTES.MAIN} Component={RootWrapper}>
        <Route path={ROUTES.MAIN} Component={OrderView} />
        <Route path={ROUTES.SETTINGS} Component={Settings} />
        <Route path={ROUTES.SETTING_CARDS} Component={SettingCards} />
        <Route path={ROUTES.NEW_CARD} Component={NewCard} />
        <Route path={ROUTES.NOTIFICATIONS} Component={Notifications} />
        <Route path={ROUTES.HISTORY} Component={History} />
        <Route path={ROUTES.SUPPORT} Component={Support} />
        <Route path={ROUTES.SUPPORT_CHAT} Component={SupportChat} />
        <Route path={ROUTES.REQUISITES} Component={DetailsType} />
        <Route path={ROUTES.NEW_IBAN} Component={NewIBAN} />
      </Route>
    </Routes>
  );
};

export default RoutesWrapper;
