import React from "react";
import Header from "../Header";
import NavBar from "../NavBar";
import { Outlet } from "react-router-dom";

const RootWrapper: React.FC = () => {
  return (
    <div className="wrapper">
      <div className="page ">
        <Header />
        <NavBar />
        <Outlet />
      </div>
    </div>
  );
};

export default RootWrapper;
