import { useQuery } from "@tanstack/react-query";
import authApi from "../config/authApi.config";

const getExchangePair = async (fromId: number, toId: number) => {
  return authApi
    .get(`/currency-pairs/exchange-rate/${fromId}/${toId}`)
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
};

const getExchangePairs = async () => {
  return authApi
    .get("/currency-pairs")
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
};

export const useGetExchangePair = (fromId: number, toId: number) => {
  const {
    data: exchangeRate,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: ["ExchangeRate", fromId, toId],
    queryFn: () => getExchangePair(fromId, toId),
    enabled: !!fromId && !!toId,
  });

  return {
    exchangeRate,
    isLoading,
    isError,
    refetch,
  };
};

export const useGetExchangePairs = () => {
  const {
    data: exchangePairs,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["ExchagePair"],
    queryFn: getExchangePairs,
  });

  return {
    exchangePairs,
    isLoading,
    isError,
  };
};
