import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../routes/routes"; // Предположим, что у вас есть маршруты для IBAN и карты
import { useTelegram } from "../../providers/TelegramProvider";

const DetailsType: React.FC = () => {
  const [selectedType, setSelectedType] = useState<string>("card"); // Устанавливаем "card" по умолчанию
  const navigate = useNavigate();
  const { webApp } = useTelegram();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault(); // Отменяем стандартное поведение формы
    if (selectedType === "iban") {
      navigate(ROUTES.NEW_IBAN); // Редирект на страницу добавления IBAN
    } else {
      navigate(ROUTES.NEW_CARD); // Редирект на страницу добавления карты
    }
  };

  useEffect(() => {
    if (webApp) {
      webApp.BackButton.onClick(() => {
        navigate(-1);
      });
      webApp.BackButton.show();
    }

    return () => {
      webApp?.BackButton.offClick(() => {
        navigate(-1);
      });
      webApp?.BackButton.hide();
    };
  }, [webApp]);

  return (
    <form className="details-type" onSubmit={handleSubmit}>
      <div className="body">
        <h1 className="details-type__title title">Выбор типа реквизитов</h1>
        <div className="details-type__options options">
          <div className="options__item">
            <input
              id="iban"
              type="radio"
              name="details-type"
              className="options__input"
              value="iban"
              checked={selectedType === "iban"}
              onChange={() => setSelectedType("iban")} // Обновляем состояние при выборе
            />
            <label htmlFor="iban" className="options__label">
              IBAN
            </label>
          </div>
          <div className="options__item">
            <input
              id="card"
              type="radio"
              name="details-type"
              className="options__input"
              value="card"
              checked={selectedType === "card"}
              onChange={() => setSelectedType("card")} // Обновляем состояние при выборе
            />
            <label htmlFor="card" className="options__label">
              Карта
            </label>
          </div>
        </div>
      </div>
      <div className="footer">
        <button type="submit" className="button">
          Продолжить
        </button>
      </div>
    </form>
  );
};

export default DetailsType;
