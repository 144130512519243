import React, { useEffect } from "react";
import * as yup from "yup";
import { useTelegram } from "../../providers/TelegramProvider";
import { useNavigate } from "react-router-dom";
import { useGetCurrencies } from "../../services/currency.services";
import { useAddPaymentMethod } from "../../services/paymentMethod.services";
import toast from "react-hot-toast";
import { ROUTES } from "../../routes/routes";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Input from "../../components/Form/Input";
import { ICurrency } from "../../models/currencies.services";
import Dropdown from "../../components/Form/Dropdown";

const NewIBAN: React.FC = () => {
  const { currencies } = useGetCurrencies();

  const { webApp } = useTelegram();
  const navigate = useNavigate();

  const { mutate: addPaymentMethod } = useAddPaymentMethod(
    () => {
      toast.success("Метод оплаты успешно добавлен!");
      navigate(ROUTES.MAIN);
    },
    (error) => {
      toast.error(error);
    }
  );

  const NewIBANFormSchema = yup.object().shape({
    Number: yup.string().required("Номер счета является обязательным полем!"),
    Currency: yup.string().required("Валюта является обязательным полем!"),
    Name: yup
      .string()
      .required("Имя держателя счета является обязательным полем!"),
    SurName: yup
      .string()
      .required("Фамилия держателя счета является обязательным полем!"),
    Tag: yup.string().required("Тег является обязательным полем!"),
  });

  const useFormReturn = useForm({
    resolver: yupResolver(NewIBANFormSchema),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useFormReturn;

  const handleSubmitForm = (data: {
    Number: string;
    Name: string;
    SurName: string;
    Tag: string;
  }) => {
    addPaymentMethod({
      type: "IBAN",
      details: JSON.stringify({
        ...data,
      }),
    });
  };

  useEffect(() => {
    if (webApp) {
      webApp.BackButton.onClick(() => {
        navigate(-1);
      });
      webApp.BackButton.show();
    }

    return () => {
      webApp?.BackButton.offClick(() => {
        navigate(-1);
      });
      webApp?.BackButton.hide();
    };
  }, [webApp]);

  useEffect(() => {
    if (currencies && currencies?.length > 0) {
      setValue("Currency", currencies[0]?.code);
    }
  }, [currencies]);

  return (
    <form
      onSubmit={handleSubmit(handleSubmitForm)}
      className="main entering-details"
    >
      <div className="body">
        <h1 className="title mb-title">Добавить IBAN</h1>
        <div className="entering-details__form form">
          <div className="entering-details__form-items form__items">
            <Controller
              name="Number"
              control={control}
              render={({ field: { value, onChange } }) => (
                <div className="input-container">
                  <Input
                    label="IBAN"
                    placeholder="Введите номер счета"
                    maxLength={19}
                    type="text"
                    value={value} // Format card number for display
                    onChange={(val) => {
                      onChange(val);
                    }}
                    error={Boolean(errors.Number?.message)}
                    helperText={errors.Number?.message}
                  />
                </div>
              )}
            />
            <Controller
              name="Currency"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Dropdown
                  id="Currency"
                  currencies={currencies?.map((currency: any) => ({
                    id: currency.id,
                    code: currency.code,
                    name: currency.name,
                    symbol: currency.symbol,
                  }))}
                  selectedCurrency={
                    currencies?.find((currency: ICurrency) => {
                      return currency?.code === value;
                    }) ||
                    (currencies && currencies?.length > 0 && currencies[0])
                  }
                  onCurrencyChange={(item) => {
                    onChange(item?.code);
                  }}
                  label="Валюта"
                />
              )}
            />

            <Controller
              name="Name"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Input
                  label="Имя"
                  placeholder="Иван"
                  value={value}
                  type="text"
                  maxLength={50}
                  onChange={onChange}
                  error={Boolean(errors.Name?.message)}
                  helperText={errors.Name?.message}
                />
              )}
            />

            <Controller
              name="SurName"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Input
                  label="Фамилия"
                  placeholder="Иванов"
                  value={value}
                  type="text"
                  maxLength={50}
                  onChange={onChange}
                  error={Boolean(errors.SurName?.message)}
                  helperText={errors.SurName?.message}
                />
              )}
            />
            <Controller
              name="Tag"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Input
                  label="Тег"
                  placeholder="Введите тег"
                  value={value}
                  type="text"
                  maxLength={50}
                  onChange={onChange}
                  error={Boolean(errors.Tag?.message)}
                  helperText={errors.Tag?.message}
                />
              )}
            />
          </div>
        </div>
      </div>
      <div className="footer">
        <button type="submit" className="button">
          Добавить
        </button>
      </div>
    </form>
  );
};

export default NewIBAN;
