import { useMutation } from "@tanstack/react-query";
import { IAuthResponse, IRegisterData } from "../models/auth.services";
import { setAuthToken } from "../config/authApi.config";
import api from "../config/api.config";

const loginUser = async (tgId: string): Promise<IAuthResponse> => {
  const response = await api.post<IAuthResponse>("/auth/login", { tgId });
  return response.data;
};

const registerUser = async (data: IRegisterData): Promise<void> => {
  await api.post("/auth/register", data);
};

// Мутация для логина
export const useLoginMutation = () => {
  return useMutation({
    mutationKey: ["Auth"],
    mutationFn: (tgId: string) => loginUser(tgId),
    onSuccess: (data) => {
      console.log(data);
      localStorage.setItem("token", data.token);
      localStorage.setItem("refreshToken", data.refreshToken);
      setAuthToken(data.token);
    },
    onError: (data) => {
      console.log(data);
    }
  });
};

export const useRegisterMutation = () => {
  return useMutation({
    mutationKey: ["Registration"],
    mutationFn: (data: IRegisterData) => registerUser(data),
  });
};
