import { useQuery } from "@tanstack/react-query";
import authApi from "../config/authApi.config";

const getCurrencies = async () => {
  return authApi
    .get("/currencies")
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
};

const getLanguages = async () => {
  return authApi
    .get("/languages")
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
};

export const useGetCurrencies = () => {
  const {
    data: currencies,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["Currencies"],
    queryFn: getCurrencies,
  });

  return {
    currencies,
    isLoading,
    isError,
  };
};

export const useGetLanguages = () => {
  const {
    data: languages,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["Languages"],
    queryFn: getLanguages,
  });

  return {
    languages,
    isLoading,
    isError,
  };
};
