import React, { useState } from "react";

interface IProps {
  languages: {
    id: number;
    code: string;
    name: string;
  }[];
  selectedLanguage: {
    id: number;
    code: string;
    name: string;
  };
  handleLanguageSelect: (language: {
    id: number;
    code: string;
    name: string;
  }) => void;
}

const LanguageDropdown: React.FC<IProps> = ({
  languages,
  selectedLanguage,
  handleLanguageSelect,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  return (
    <div className="form__item">
      <label htmlFor="" className="form__label">
        Язык интерфейса
      </label>

      {/* Текущий выбранный язык */}
      <div className="dropdown">
        <button
          type="button"
          className="form__control form-control"
          onClick={toggleDropdown}
        >
          <img
            className="form-control__img"
            src={`/img/country/${selectedLanguage?.code}.svg`}
            alt={selectedLanguage?.name}
          />
          <p className="form-control__text">{selectedLanguage?.name}</p>
          <img
            className={`form-control__arrow ${isDropdownOpen ? "open" : ""}`}
            src="/img/svg/arrow.svg"
            alt="arrow"
          />
        </button>

        {/* Выпадающий список с языками */}
        {isDropdownOpen && (
          <div className="dropdown__menu">
            {languages.map(
              (language: { id: number; code: string; name: string }) => (
                <div
                  key={language?.code}
                  className="dropdown__item"
                  onClick={() => {
                    setIsDropdownOpen(false);
                    handleLanguageSelect(language);
                  }}
                >
                  <img
                    className="dropdown__img"
                    src={`/img/country/${language?.code}.svg`}
                    alt={language?.name}
                  />
                  <p className="dropdown__text">{language?.name}</p>
                </div>
              )
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default LanguageDropdown;
