import React from "react";
import { useConfirmTransaction } from "../../services/order.services";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { ROUTES } from "../../routes/routes";

const TraderPaid: React.FC = () => {
  const orderId = localStorage.getItem("CURRENT_ORDER_ID");

  const { mutate: confirmTransaction } = useConfirmTransaction(
    () => {
      localStorage.setItem("ORDER_STATUS", "ORDER_COMPLETED");
    },
    () => {
      toast.error("Что-то пошло не так... Попробуйте позже!");
    }
  );

  const handleSubmit = () => {
    confirmTransaction({
      orderId: orderId!,
    });
  };

  return (
    <div className="main check">
      <div className="body">
        <div className="check__body">
          <img className="check__logo" src="/img/logo.svg" alt="logo bobster" />

          <p className="check__text">
            Трейдер уведомил о переводе. <br />
            Проверьте зачисление
          </p>
          <div className="check__actions">
            <a href="#" className="check__link">
              Чек о переводе
            </a>
            <button className="check__btn-pdf">
              <img src="/img/svg/pdf.svg" alt="" />
            </button>
          </div>
        </div>
      </div>
      <div className="footer">
        <p className="check__important">Обязательно подтвердите перевод</p>
        <div className="check__buttons">
          <Link to={ROUTES.SUPPORT} className="button button--blue-border">
            Перевод не получен
          </Link>
          <button onClick={handleSubmit} className="button">
            Перевод получен
          </button>
        </div>
      </div>
    </div>
  );
};

export default TraderPaid;
