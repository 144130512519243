import React, { useEffect, useState } from "react";
import RoutesWrapper from "./routes/RoutesWrapper";
import { useTelegram } from "./providers/TelegramProvider";
import { useAuth } from "./hooks/useAuth";
import {
  useGetLanguage,
  useGetLanguageFile,
} from "./services/languages.services";
import { I18nextProvider } from "react-i18next"; // Wrapper for providing i18n context
import i18n from "./i18n/i18n";
import Loading from "./components/Loading";
import { useGetLanguages } from "./services/settings.service";

const App: React.FC = () => {
  const [language, setLanguage] = useState<number>();
  const [isLanguageLoaded, setIsLanguageLoaded] = useState(false);
  const [loadingItems, setLoadingItems] = useState<string[]>([]); // Массив для элементов загрузки
  const [isAuthLoading, setIsAuthLoading] = useState(true); // Состояние для авторизации

  const { webApp } = useTelegram();

  const { languages, isLoading: isLanguagesLoading } = useGetLanguages();
  const { language: currentLanguage, isLoading: isLanguageLoading } =
    useGetLanguage(language?.toString()!);
  const { languageFile, isLoading: isLanguageFileLoading } = useGetLanguageFile(
    currentLanguage?.file_path
  );

  const tgId = webApp?.initDataUnsafe?.user?.id?.toString() || "";
  const userName = webApp?.initDataUnsafe?.user?.username || "";
  const referralCode =
    new URLSearchParams(window.location.search).get("ref") || "";

  const { isAuthenticated, isAuthCompleted } = useAuth(
    tgId,
    userName,
    referralCode
  );

  useEffect(() => {
    const loadingList: string[] = [];
    if (isLanguagesLoading) loadingList.push("Languages");
    if (isLanguageLoading) loadingList.push("Current Language");
    if (isLanguageFileLoading) loadingList.push("Language File");
    setLoadingItems(loadingList);

    if (!localStorage.getItem("LANGUAGE") && languages) {
      setLanguage(languages[0].id);
      localStorage.setItem("LANGUAGE", languages[0].id!);
    } else {
      setLanguage(+localStorage.getItem("LANGUAGE")!);
    }

    if (languageFile) {
      i18n.addResourceBundle(
        currentLanguage.code,
        "translation",
        languageFile,
        true,
        true
      );
      i18n.changeLanguage(currentLanguage.code);
      setIsLanguageLoaded(true);
    }
  }, [
    isLanguagesLoading,
    isLanguageLoading,
    isLanguageFileLoading,
    languageFile,
    currentLanguage,
    languages,
  ]);

  // Состояние загрузки для авторизации
  useEffect(() => {
    if (isAuthCompleted) {
      setIsAuthLoading(false); // Завершаем загрузку авторизации
    }
  }, [isAuthCompleted]);

  const isLoading =
    isLanguagesLoading ||
    isLanguageLoading ||
    isLanguageFileLoading ||
    isAuthLoading;

  if (!isAuthCompleted) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return (
      <Loading loadingItems={loadingItems} isAuthLoading={isAuthLoading} />
    );
  }

  if (isLoading || !isLanguageLoaded) {
    return (
      <Loading loadingItems={loadingItems} isAuthLoading={isAuthLoading} />
    );
  }

  return (
    <I18nextProvider i18n={i18n}>
      <RoutesWrapper />
    </I18nextProvider>
  );
};

export default App;
