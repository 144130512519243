import { useMutation, useQuery } from "@tanstack/react-query";
import authApi from "../config/authApi.config";
import { INewOrderForm, IOrder } from "../models/order.service";

const createOrder = async (data: INewOrderForm) => {
  return authApi
    .post("/orders", data)
    .then((res) => res.data as IOrder)
    .catch((error) => {
      throw new Error(error);
    });
};

const getOrder = async (orderId: string) => {
  return authApi
    .get(`/orders/by/${orderId}`)
    .then((res) => res.data as IOrder)
    .catch((error) => {
      throw new Error(error);
    });
};

const getOrderHistory = async ({
  page = 1,
  limit = 10,
  keyword = "",
  statuses = [],
  orderTypes = [],
  isCash = null,
  sort = "",
}: {
  page: number;
  limit: number;
  keyword?: string;
  statuses?: number[];
  orderTypes?: number[];
  isCash?: boolean | null;
  sort?: string;
}) => {
  const params = {
    page,
    limit,
    // keyword,
    // statuses,
    // orderTypes,
    // isCash,
    // sort,
  };

  // Filter out any params that are undefined or null
  const filteredParams = Object.fromEntries(
    Object.entries(params).filter(([_, v]) => v !== undefined && v !== null)
  );

  return authApi
    .get("/orders/byUser", { params: filteredParams })
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
};

const clientSendMoney = async (data: { orderId: string }) => {
  return authApi
    .patch("/orders/customerSent", data)
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
};

const confirmTransaction = async (data: { orderId: string }) => {
  return authApi
    .patch("/orders/customerConfirm", data)
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
};

const leaveReview = async (data: {
  orderId: string;
  traderId: string;
  customerId: string;
  type: string;
  score: number;
  content: string;
}) => {
  return authApi
    .post("/reviews", data)
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
};

export const useCreateOrder = (
  onSuccess?: (data: IOrder) => void,
  onError?: (error: any) => void
) => {
  return useMutation({
    mutationKey: ["CreateOrder"],
    mutationFn: (data: INewOrderForm) => createOrder(data),
    onSuccess,
    onError,
  });
};

export const useGetOrderHistory = ({
  page = 1,
  limit = 10,
  keyword = "",
  statuses = [],
  orderTypes = [],
  isCash = null,
  sort = "",
}: {
  page: number;
  limit: number;
  keyword?: string;
  statuses?: number[];
  orderTypes?: number[];
  isCash?: boolean | null;
  sort?: string;
}) => {
  const {
    data: orders,
    isLoading,
    isError,
  } = useQuery({
    queryKey: [
      "OrderHistory",
      page,
      limit,
      keyword,
      statuses,
      orderTypes,
      isCash,
      sort,
    ],
    queryFn: () =>
      getOrderHistory({
        page,
        limit,
        keyword,
        statuses,
        orderTypes,
        isCash,
        sort,
      }),
  });

  return { orders, isLoading, isError };
};

export const useGetOrder = (orderId: string) => {
  const {
    data: order,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: ["Order"],
    queryFn: () => getOrder(orderId),
    refetchInterval: 30000,
  });

  return {
    order,
    isLoading,
    isError,
    refetch,
  };
};

export const useClientSendMoney = (
  onSuccess?: () => void,
  onError?: () => void
) => {
  return useMutation({
    mutationKey: ["ClientSendFunds"],
    mutationFn: (data: { orderId: string }) => clientSendMoney(data),
    onSuccess,
    onError,
  });
};

export const useConfirmTransaction = (
  onSuccess?: () => void,
  onError?: () => void
) => {
  return useMutation({
    mutationKey: ["ConfirmTransaction"],
    mutationFn: (data: { orderId: string }) => confirmTransaction(data),
    onSuccess,
    onError,
  });
};

export const useLeaveReview = (
  onSuccess?: () => void,
  onError?: () => void
) => {
  return useMutation({
    mutationKey: ["LeaveReview"],
    mutationFn: (data: {
      orderId: string;
      traderId: string;
      customerId: string;
      type: string;
      score: number;
      content: string;
    }) => leaveReview(data),
    onSuccess,
    onError,
  });
};
