import React, { useEffect } from "react";
import * as yup from "yup";
import { Link } from "react-router-dom";
import { ROUTES } from "../../routes/routes";
import {
  useGetCurrencies,
  useGetLanguages,
} from "../../services/settings.service";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Dropdown from "../../components/Form/Dropdown";
import { ICurrency } from "../../models/currencies.services";
import LanguageDropdown from "../../components/Form/LanguageDropdown";
import { useGetMe, useUpdateUser } from "../../services/user.services";
import toast from "react-hot-toast";

const Settings: React.FC = () => {
  const { me } = useGetMe();
  const { currencies } = useGetCurrencies();
  const { languages } = useGetLanguages();
  const { mutate: updateUser, isPending } = useUpdateUser(
    () => {
      console.log("hi");
      toast.success("Настройки успешно сохранены!");
    },
    (error) => {
      toast.error(error);
    }
  );

  const SettingsFormSchema = yup.object().shape({
    myCurrencyId: yup
      .number()
      .required("Валюта переводов является обязательным полем!"),
    languageId: yup
      .number()
      .required("Язык интерфейса является обязательным полем!"),
    receiverCurrencyId: yup
      .number()
      .required("Валюта получения является обязательным полем!"),
  });

  const useFormReturn = useForm({
    resolver: yupResolver(SettingsFormSchema),
    defaultValues: {
      myCurrencyId: me?.myCurrency?.id,
      languageId: me?.language?.id || languages?.[0]?.id,
      receiverCurrencyId: me?.receiverCurrency?.id,
    },
  });

  const {
    handleSubmit,
    setValue,
    control,
    formState: { isDirty, errors },
  } = useFormReturn;

  useEffect(() => {
    if (me && languages && currencies) {
      setValue("myCurrencyId", me?.myCurrency?.id || currencies[1]?.id);
      setValue("languageId", me?.language?.id || languages[0]?.id);
      setValue(
        "receiverCurrencyId",
        me?.receiverCurrency?.id || currencies[0]?.id
      );
    }
  }, [me, languages, setValue, currencies]);

  const handleSubmitSettingsForm = (data: {
    myCurrencyId: number;
    languageId: number;
    receiverCurrencyId: number;
  }) => {
    updateUser(data);
  };

  console.log(errors);

  return (
    <form
      onSubmit={handleSubmit(handleSubmitSettingsForm)}
      className="main settings"
    >
      <div className="body">
        <h1 className="title mb-title">Настройки</h1>
        <div className="settings__form form">
          <div className="settings__form-items form__items">
            <Controller
              name="myCurrencyId"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Dropdown
                  id="myCurrency"
                  currencies={currencies?.map((currency: any) => ({
                    id: currency.id,
                    code: currency.code,
                    name: currency.name,
                    symbol: currency.symbol,
                  }))}
                  selectedCurrency={
                    currencies?.find(
                      (currency: ICurrency) => currency?.id === value
                    ) ||
                    (currencies && currencies?.length > 0 && currencies[0])
                  }
                  onCurrencyChange={(item) => onChange(item?.id)}
                  label="Валюта переводов"
                />
              )}
            />

            <Controller
              name="languageId"
              control={control}
              render={({ field: { value, onChange } }) => (
                <LanguageDropdown
                  selectedLanguage={languages?.find(
                    (item: any) => item.id === value
                  )}
                  languages={languages}
                  handleLanguageSelect={(language) => onChange(language.id)}
                />
              )}
            />

            <Controller
              name="receiverCurrencyId"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Dropdown
                  id="receiverCurrency"
                  currencies={currencies?.map((currency: any) => ({
                    id: currency.id,
                    code: currency.code,
                    name: currency.name,
                    symbol: currency.symbol,
                  }))}
                  selectedCurrency={
                    currencies?.find(
                      (currency: ICurrency) => currency?.id === value
                    ) ||
                    (currencies && currencies?.length > 0 && currencies[0])
                  }
                  onCurrencyChange={(item) => onChange(item?.id)}
                  label="Валюта получения"
                />
              )}
            />

            <Link
              to={ROUTES.SETTING_CARDS}
              className="button button--blue-border"
            >
              Мои карты
            </Link>
          </div>
        </div>
        <div className="settings__code">
          <img src="/img/qr.jpg" alt="" className="settings__code-qr" />
          <div className="settings__code-content">
            <label className="settings__code-label">
              Код для сделки со <br /> сторонним трейдером:
            </label>
            <a href="#" className="settings__code-link code">
              <p className="code__value">120982347</p>
              <button className="copy-btn">
                <svg className="copy-btn__icon">
                  <use xlinkHref="img/sprite.svg#copy"></use>
                </svg>
              </button>
            </a>
          </div>
        </div>
      </div>
      <div className="footer">
        <button
          type="submit"
          className="button"
          disabled={!isDirty || isPending}
        >
          Сохранить изменения
        </button>
      </div>
    </form>
  );
};

export default Settings;
