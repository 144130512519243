import React, { useEffect } from "react";
import * as yup from "yup";
import { useGetOrder, useLeaveReview } from "../../services/order.services";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const OrderCompleted: React.FC = () => {
  const orderId = localStorage.getItem("CURRENT_ORDER_ID");
  const { order } = useGetOrder(orderId!);

  const { mutate: leaveReview } = useLeaveReview(
    () => {
      toast.success("Отзыв успешно оставлен!");
      localStorage.removeItem("CURRENT_ORDER_ID");
      localStorage.removeItem("ORDER_STATUS");
    },
    () => {
      toast.error("Что-то пошло не так... Попробуйте позже!");
    }
  );

  const ReviewFormSchema = yup.object().shape({
    orderId: yup.string().required("Идентификатор заказа обязателен."),
    customerId: yup.string().required("Идентификатор клиента обязателен."),
    traderId: yup.string().required("Идентификатор трейдера обязателен."),
    type: yup.string().required("Тип отзыва обязателен."),
    score: yup.number().required("Оценка обязательна.").min(1).max(5),
    content: yup.string().required("Комментарий обязателен!").max(500),
  });

  const { register, handleSubmit, watch, formState, setValue } = useForm({
    resolver: yupResolver(ReviewFormSchema),
    defaultValues: {
      orderId: orderId!,
      customerId: order?.customer.id.toString()!,
      traderId: order?.trader1?.id.toString()!,
      type: "Fast",
      score: 3,
      content: "",
    },
  });

  const { errors } = formState;

  const contentLength = watch("content")?.length || 0;

  const onSubmitReviewForm = (data: any) => {
    leaveReview(data);
  };

  useEffect(() => {
    if (order) {
      setValue("orderId", order.orderId);
      setValue("customerId", order.customer.id.toString());
      setValue("traderId", order.trader1.id.toString());
    }
  }, [order]);

  return (
    <form
      className="main deal-done"
      onSubmit={handleSubmit(onSubmitReviewForm)}
    >
      <div className="body">
        <div className="deal-done__done done">
          <img className="done__icon" src="/img/svg/done.svg" alt="success" />
          <p className="done__text">
            Сделка по переводу <span className="done__text-code">#457664</span>{" "}
            завершена
          </p>
        </div>
        <div className="deal-done__rating rating">
          <h2 className="deal-done__rating-title">Оцените сделку</h2>
          <div className="rating__items">
            {[5, 4, 3, 2, 1].map((score) => (
              <React.Fragment key={score}>
                <input
                  className="rating__item"
                  type="radio"
                  {...register("score")}
                  value={score}
                  id={`score-${score}`}
                />
                <label className="rating__label" htmlFor={`score-${score}`} />
              </React.Fragment>
            ))}
          </div>
          {errors.score && <p className="error">{errors.score.message}</p>}
        </div>
        <div className="deal-done__review review">
          <h2 className="deal-done__rating-title">Выберите тип отзыва</h2>
          <div className="review__types">
            {[
              { value: "Fast", label: "Быстро" },
              { value: "Safety", label: "Безопасно" },
              { value: "GoodPrice", label: "Хорошая цена" },
            ].map((type) => (
              <React.Fragment key={type.value}>
                <input
                  className="review__item"
                  type="radio"
                  {...register("type")}
                  value={type.value}
                  id={`type-${type.value}`}
                />
                <label className="review__label" htmlFor={`type-${type.value}`}>
                  {type.label}
                </label>
              </React.Fragment>
            ))}
          </div>
          {errors.type && <p className="error">{errors.type.message}</p>}
        </div>
        <div className="deal-done__feedback feedback">
          <textarea
            {...register("content")}
            name="content"
            className="feedback__textarea"
            placeholder="Введите ваш отзыв..."
          />
          <div className="feedback__count">{contentLength}/500</div>
          {errors.content && <p className="error">{errors.content.message}</p>}
        </div>
      </div>
      <div className="footer">
        <button type="submit" className="button">
          Отправить отзыв
        </button>
      </div>
    </form>
  );
};

export default OrderCompleted;
