import React from "react";

interface IProps {
  label: string;
  leftIcon?: string;
  value: string;
  onChange: (value: string) => void;
  placeholder: string;
  error?: boolean;
  helperText?: string;
  maxLength?: number;
  type?: string;
}

const Input: React.FC<IProps> = ({
  label,
  leftIcon,
  value,
  onChange,
  placeholder,
  error,
  helperText,
  maxLength,
  type,
}) => {
  return (
    <div className="form__item">
      <label htmlFor="" className="form__label">
        {label}
      </label>
      <div className="form-control">
        {leftIcon && (
          <img className="form-control__img" src={leftIcon} alt={leftIcon} />
        )}
        <input
          className="form-control__input"
          type={type}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          placeholder={placeholder}
          maxLength={maxLength}
        />
      </div>
      {error && <p className="error">{helperText}</p>}
    </div>
  );
};

export default Input;
