import React from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../../routes/routes";

const NavBar: React.FC = () => {
  return (
    <nav className="navigation">
      <ul className="navigation__list">
        <li className="navigation__item">
          <Link to={ROUTES.MAIN} className="navigation__link">
            <svg className="navigation__link-icon">
              <use xlinkHref="/img/sprite.svg#document"></use>
            </svg>
            <p className="navigation__link-text">Перевести</p>
          </Link>
        </li>
        <li className="navigation__item">
          <Link to={ROUTES.SUPPORT} className="navigation__link">
            <svg className="navigation__link-icon">
              <use xlinkHref="/img/sprite.svg#chatbubbles"></use>
            </svg>
            <p className="navigation__link-text">Поддержка</p>
          </Link>
        </li>
        <li className="navigation__item">
          <Link to={ROUTES.HISTORY} className="navigation__link">
            <svg className="navigation__link-icon">
              <use xlinkHref="/img/sprite.svg#time"></use>
            </svg>
            <p className="navigation__link-text">История</p>
          </Link>
        </li>
        <li className="navigation__item">
          <Link to={ROUTES.SETTINGS} className="navigation__link">
            <svg className="navigation__link-icon">
              <use xlinkHref="/img/sprite.svg#settings"></use>
            </svg>
            <p className="navigation__link-text">Настройки</p>
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default NavBar;
