import React, { useEffect, useState } from "react";
import { useGetOrder } from "../../services/order.services";

const OrderTransferWaiting: React.FC = () => {
  const orderId = localStorage.getItem("CURRENT_ORDER_ID");
  const { order, refetch } = useGetOrder(orderId!);

  const [timeLeft, setTimeLeft] = useState<number | null>(null);

  useEffect(() => {
    if (!order || !order.traderDepositedAt) return;

    const traderDepositedTime = new Date(order.traderDepositedAt).getTime();
    const endTime = traderDepositedTime + 15 * 60 * 1000;

    const updateTimer = () => {
      const currentTime = Date.now();
      const remainingTime = endTime - currentTime;

      if (remainingTime <= 0) {
        setTimeLeft(0);
      } else {
        setTimeLeft(remainingTime);
      }
    };

    const intervalId = setInterval(updateTimer, 1000);

    return () => clearInterval(intervalId);
  }, [order]);

  useEffect(() => {
    if (order && order.status === 5) {
      localStorage.setItem("ORDER_STATUS", "TRADER_PAID");
    }
  }, [order]);

  const formatTime = (time: number) => {
    const hours = Math.floor(time / (1000 * 60 * 60));
    const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((time % (1000 * 60)) / 1000);
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}:${String(seconds).padStart(2, "0")}`;
  };

  return (
    <div className="main client-order-control">
      <div className="body">
        <div className="client-order-control__info order-control-info">
          <h1 className="order-control-info__title title">Детали перевода</h1>
          <p className="order-control-info__text">
            Ордер{" "}
            <span className="order-control-info__text--order">
              #{order?.orderId}
            </span>{" "}
            создан, посредник уведомлен о зачислении!
          </p>
        </div>
        <div className="client-order-control__time transaction-detail-time">
          <p className="transaction-detail-time__text">Время до зачисления</p>
          <div className="transaction-detail-time__timer">
            {timeLeft !== null ? formatTime(timeLeft) : "Загрузка..."}
          </div>
        </div>
        <div className="client-order-control__status order-control-status">
          <label htmlFor="" className="order-control-status__label">
            Статус перевода:{" "}
          </label>
          <div className="order-control-status__value">
            В процессе выполнения
          </div>
        </div>
        <div className="client-order-control__summary transaction-detail-summary">
          <div className="transaction-detail-summary__amount">
            50000 {order?.customerCurrency.code}
          </div>
          <p className="transaction-detail-summary__text">
            Перевод будет зачислен на карту
            <span> 1234 1234 1234 8888</span>
          </p>
        </div>
      </div>
      <div className="client-order-control__footer footer">
        <button onClick={() => refetch()} type="submit" className="button">
          Обновить статус
        </button>
        <div className="client-order-control__actions">
          <a
            href="#"
            className="client-order-control__btn button button--gray-border"
          >
            Перевод не получен (чат){" "}
          </a>
          <button className="client-order-control__btn button button--orange-border">
            Отменить ордер
          </button>
        </div>
      </div>
    </div>
  );
};

export default OrderTransferWaiting;
