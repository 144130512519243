import React, { useState } from "react";
import { useGetOrderHistory } from "../../services/order.services";

const History: React.FC = () => {
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [keyword, setKeyword] = useState("");
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const [sort, setSort] = useState("");

  const { orders, isLoading, isError } = useGetOrderHistory({
    page,
    limit,
    // keyword,
    // statuses: selectedFilters.includes("dispute") ? [2] : [],
    // orderTypes: selectedFilters.includes("direct") ? [1] : [],
    // isCash: selectedFilters.includes("cash") ? true : null,
    // sort,
  });

  const handleLoadMore = () => {
    setPage((prev) => prev + 1);
  };

  return (
    <div className="main transactions">
      <div className="body">
        <h1 className="transactions__title title mb-title">История</h1>
        <div className="transactions__checkbox checkbox">
          <input
            id="open"
            type="checkbox"
            name="open"
            className="checkbox__input"
          />
          <label htmlFor="open" className="checkbox__label">
            Только открытые
          </label>
        </div>
        <div className="transactions__items">
          <a
            href="#"
            className="transactions__item item-transactions item-transactions--history"
          >
            <div className="item-transactions__data item-transactions__data--order">
              #457664{" "}
            </div>
            <div className="item-transactions__data">12.07.2024 16:30</div>
            <div className="item-transactions__data item-transactions__data--amount">
              1 000 TRY
            </div>
            <div className="item-transactions__data">
              <div className="item-transactions__status item-transactions__status--open">
                <img
                  className="item-transactions__status-icon"
                  src="/img/svg/reload.svg"
                  alt=""
                />
              </div>
            </div>
          </a>
          <a
            href="#"
            className="transactions__item item-transactions item-transactions--history"
          >
            <div className="item-transactions__data item-transactions__data--order">
              #457664{" "}
            </div>
            <div className="item-transactions__data">12.07.2024 16:30</div>
            <div className="item-transactions__data item-transactions__data--amount">
              1 000 TRY
            </div>
            <div className="item-transactions__data">
              <div className="item-transactions__status item-transactions__status--done">
                <img
                  className="item-transactions__status-icon"
                  src="/img/svg/check-chat.svg"
                  alt=""
                />
              </div>
            </div>
          </a>
          <a
            href="#"
            className="transactions__item item-transactions item-transactions--history"
          >
            <div className="item-transactions__data item-transactions__data--order">
              #457664{" "}
            </div>
            <div className="item-transactions__data">12.07.2024 16:30</div>
            <div className="item-transactions__data item-transactions__data--amount">
              1 000 TRY
            </div>
            <div className="item-transactions__data">
              <div className="item-transactions__status item-transactions__status--reject">
                <img
                  className="item-transactions__status-icon"
                  src="/img/svg/close.svg"
                  alt=""
                />
              </div>
            </div>
          </a>
        </div>
      </div>
      <div className="footer">
        <button onClick={handleLoadMore} className="button button--gray-border">
          Загрузить еще
        </button>
      </div>
    </div>
  );
};

export default History;
